import React from "react";
import "./Header.css";
import { LuLogOut } from "react-icons/lu";

const Header = () => {
  const handleLogout = () => {
    // Clear token from local storage
    localStorage.removeItem('token');
    // Redirect to login page
    window.location.href = '/signin';
  };

  return (
    <header className="header">
      <nav className="nav-links">
        <a href="/">Page d'accueil</a> &gt; <a href="/dashboard">Statistiques d'accès</a>
      </nav>
      <button 
        onClick={handleLogout} 
        className="logout-button"
      >
        <LuLogOut className="logout-icon" />
        <span className="logout-text">Déconnexion</span>
      </button>
    </header>
  );
};

export default Header;
