import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import "./StatisticsComponent.css"; // Ensure this file is updated with the new styles

const StatisticsComponent = ({ uniqueClientsCount,totalClientCountLeftTheCall,totalClientCount, questionsSatisfied, questionsUnsatisfied, bounceRate }) => (
  <div className="statistics">
    <div className="stat">
    <span>Nombre de clients uniques reçus
    <span className="info-icon">
          <HiOutlineInformationCircle />
  
          <div className="tooltip">
          Cela représente le nombre total de clients uniques. Chaque client unique est identifié par une combinaison distincte d'adresse IP et de jeton, garantissant que chaque client est compté une seule fois, même s'il accède au service plusieurs fois .

          </div>
        </span>
        </span>
      <input type="text" value={uniqueClientsCount ?? "Loading..."} readOnly />
    </div>
    <div className="stat">
    
    <span>Nombre de réponses satisfaites
    <span className="info-icon">
          <HiOutlineInformationCircle />
          <div className="tooltip">
          Cela représente le nombre total de réponses satisfaites. Une réponse « satisfaisante » est celle pour laquelle le client a répondu positivement après la question de l'assistant.
          </div>
        </span>
      </span>

      <input type="text" value={questionsSatisfied ?? "Loading..."} readOnly />
    </div>
    <div className="stat">
    <span>Nombre de réponses insatisfaites
    <span className="info-icon">
          <HiOutlineInformationCircle />
          <div className="tooltip">
          Cela représente le nombre total de réponses insatisfaites. Une réponse « insatisfaisante » est celle pour laquelle le client a répondu négativement après la question de l'assistant.
          </div>
        </span>

      </span>

      <input type="text" value={questionsUnsatisfied ?? "Loading..."} readOnly />
    </div>
    <div className="stat">
    <span>Taux de rebond
    <span className="info-icon">
          <HiOutlineInformationCircle />
          <div className="tooltip">
          Le nombre total de clients ayant quitté l'appel avant 10 secondes = {totalClientCountLeftTheCall} divisé par le nombre total de clients reçus = {totalClientCount}.
          </div>
        </span>
      </span>
      <input type="text" value={`${bounceRate ?? "0.00"}%`} readOnly />
    </div>
  </div>
);

export default StatisticsComponent;
