import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart } from '@mui/x-charts/PieChart';
import './PieChart.css'; // Ensure you have a CSS file for styling

export default function BasicPie() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL

      try {

        const response =  await axios.get(`${baseUrl}/api/total-clients-clicked-before-10-seconds-tf`);
        const apiData = response.data;
        // Calculate total count
        const total = apiData.totalCountTrue + apiData.totalCountFalse;
        setTotalCount(total);

        // Transform the API data into the format required by PieChart
        const chartData = [
          { id: 0, value: apiData.totalCountTrue, label: 'avant 10 S'},
          { id: 1, value: apiData.totalCountFalse,label: 'après 10 S' }
        ];

        setData(chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="pie-chart-container" style={{ width: '100%', height: 350 }}>
<h4 style={{ textAlign: 'center' }}>Total des clients ayant quitté l'appel avant et après 10 secondes (Depuis toujours)</h4>
<p>Total des appels : {totalCount}</p>
      <PieChart
        series={[
          {
            data: data,
          },
        ]}
        width={400}
        height={200}
      />
    </div>
  );
}
