import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const messageStyle = {
  mb: 2,
  padding: '8px',
  borderRadius: '4px',
  maxWidth: '80%',
};

const iaStyle = {
  ...messageStyle,
  bgcolor: '#e3f2fd', // Light blue background for IA messages
  alignSelf: 'flex-start',
};

const clientStyle = {
  ...messageStyle,
  bgcolor: '#fff3e0', // Light orange background for Client messages
  alignSelf: 'flex-end',
};

export default function MessageDialog({ open, onClose, content }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Historique de conversation
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            overflowY: 'auto',
            maxHeight: '60vh', // Limit height of the dialog content
          }}
        >
          {content.map((messagePair, index) => {
            if (!Array.isArray(messagePair) || messagePair.length !== 1) {
              console.error(`Expected 'content' to be an array of single messages but got ${JSON.stringify(messagePair)}`);
              return null;
            }

            const [message] = messagePair;

            // Determine sender from message
            const isIA = message.startsWith('IA:');

            return (
              <React.Fragment key={index}>
                <Box sx={isIA ? iaStyle : clientStyle}>
                  <Typography variant="body2">
                    {message}
                  </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
              </React.Fragment>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
