import React, { useState } from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField'; // Import TextField
import dayjs from 'dayjs';
import "./FiltersComponent.css";

// Example suggestions data
const suggestions = [

  { id:'1',slug : `osmoseur-domestique-1`},
  {id:'2' , slug :'osmoseur-domestique-2'}
];

const FiltersComponent = ({ searchTerm, startDate, endDate, setSearchTerm, setStartDate, setEndDate, handleSearch }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [tempStartDate, setTempStartDate] = useState(dayjs(startDate));
  const [tempEndDate, setTempEndDate] = useState(dayjs(endDate));

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.id.includes(value) || suggestion.slug.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (id) => {
    setSearchTerm(id);
    setFilteredSuggestions([]);
  };

  const handleDateChange = (date, type) => {
    if (type === 'start') {
      setTempStartDate(date);
      setStartDate(date ? date.format('YYYY-MM-DD') : '');
    } else if (type === 'end') {
      setTempEndDate(date);
      setEndDate(date ? date.format('YYYY-MM-DD') : '');
    }
  };

  return (
    <div className="filters">
      <div className="filter">
      <label htmlFor="category-filter">ID du Produit</label>
      <div style={{ position: 'relative' }}>
          <input
            type="text"
            id="category-filter"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Entrez l'ID du produit"
            />
          {filteredSuggestions.length > 0 && (
            <div style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              border: '1px solid #ddd',
              borderRadius: '4px',
              backgroundColor: '#fff',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              zIndex: 1000,
              maxHeight: '150px', // Limit height of the suggestions dropdown
              overflowY: 'auto' // Add scroll if too many suggestions
            }}>
              {filteredSuggestions.map((suggestion) => (
                <div
                  key={suggestion.id}
                  style={{
                    padding: '8px',
                    borderBottom: '1px solid #ddd',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSuggestionClick(suggestion.id)}
                >
                  <div><strong>{suggestion.id}</strong></div>
                  <div>{suggestion.slug}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="filter">
      <label>Date de début</label>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker
          className="datepicker"
            value={tempStartDate}
            onChange={(date) => handleDateChange(date, 'start')}
            textField={(params) => <TextField {...params} 
            />}
          />
        </LocalizationProvider>
      </div>
      <div className="filter">
        <label >Date de fin</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="datepicker"

            value={tempEndDate}
            onChange={(date) => handleDateChange(date, 'end')}
            textField={(params) => <TextField {...params}
            
            />

          }
          />
        </LocalizationProvider>
      </div>

      <div className="search-button">
        <Button
          variant="contained"
          color="success"
          onClick={handleSearch}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default FiltersComponent;
