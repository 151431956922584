import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import axios from 'axios';

export default function DailyTrendsLineChart() {
  const [chartData, setChartData] = React.useState({ dates: [], buyers: [], nonBuyers: [] });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL

        const result =  await axios.get(`${baseUrl}/api/transcript`);
        const now = new Date();
        const pastWeek = new Date(now);
        pastWeek.setDate(now.getDate() - 6); // Start 7 days ago, including today

        const filteredData = result.data.filter(item => new Date(item.datetime) >= pastWeek);

        // Generate array of dates for the past 7 days including today
        const datesArray = [];
        for (let i = 0; i < 7; i++) {
          const date = new Date(pastWeek);
          date.setDate(date.getDate() + i);
          datesArray.push(date.toDateString());
        }

        // Initialize counts for each date
        const counts = datesArray.reduce((acc, date) => {
          acc[date] = { buyers: 0, nonBuyers: 0 };
          return acc;
        }, {});

        // Populate counts from filtered data
        filteredData.forEach(item => {
          const date = new Date(item.datetime).toDateString();
          if (counts[date]) {
            if (item.buy) {
              counts[date].buyers += 1;
            } else {
              counts[date].nonBuyers += 1;
            }
          }
        });

        // Extract data for chart
        const buyers = datesArray.map(date => counts[date].buyers);
        const nonBuyers = datesArray.map(date => counts[date].nonBuyers);

        setChartData({ dates: datesArray, buyers, nonBuyers });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: '100%', height: 350 }}>
      <h4 style={{ textAlign: 'center' }}>
      Acheteurs et Non-Acheteurs - Activité d'Achat Pendant l'Appel au Cours des 7 Derniers Jours
      </h4>
      <LineChart
        xAxis={[{ data: chartData.dates, scaleType: 'band' }]}
        series={[
          { data: chartData.buyers, label: 'Acheteurs', color: 'blue' },  // Customize colors
          { data: chartData.nonBuyers, label: 'Non-Acheteurs', color: 'red'  } // Customize colors
        ]}
        height={300}

        yAxis={[{
          tickFormatter: (value) => Math.round(value), // Ensure y-axis displays whole numbers
          min: 0, // Ensure y-axis starts from 0
          max: Math.max(...chartData.buyers, ...chartData.nonBuyers) + 1 // Dynamically set max value
        }]}
        tooltip={{
          show: true,
          formatter: ({ value }) => `${value}` // Display the value on hover
        }}
      />
    </div>
  );
}
