import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios'; // Import axios

export default function FAQUpdateDialog({ open, onClose, initialQuestion, initialAnswer,onSave }) {
  const [question, setQuestion] = React.useState(initialQuestion.substring(8));
  const [answer, setAnswer] = React.useState(initialAnswer.substring(4));
  console.log("initialQuestion")
  console.log(initialQuestion)
  console.log("initialAnswer")
  console.log(initialAnswer)

  const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL
  const handleChangeClient = (event) => {
    setQuestion(event.target.value);
  };
  const handleChangeIA = (event) => {
    setAnswer(event.target.value);
  };

  const handleSave = async () => {
    try {
      // Send a POST request to save the FAQ content
      await axios.post(`${baseUrl}/api/admin/faq`, {
        question,
        answer
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Notify parent component (if needed)
      onSave(question);
      onClose();
    } catch (error) {
      console.error('Error saving FAQ:', error);
      // Handle error (e.g., show notification)
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      PaperProps={{
        style: {
          width: '60%', 
          maxWidth: '600px', 
          margin: 'auto' 
        }
      }}
    >
      <DialogTitle>Mettre à jour la FAQ</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="client-question"
          label="Question du client"
          type="text"
          multiline
          fullWidth
          variant="standard"
          value={question}
          onChange={handleChangeClient}
          sx={{ 
            '& textarea': { 
              overflowY: 'auto' 
            }
          }}
        />

<TextField
          autoFocus
          margin="dense"
          id="ia-answer"
          label="Réponse IA"
          type="text"
          multiline
          fullWidth
          variant="standard"
          value={answer}
          onChange={handleChangeIA}
          sx={{ 
            '& textarea': { 
              overflowY: 'auto' 
            }
          }}
        />

    </DialogContent>

      <DialogActions>
      <Button onClick={onClose}>ANNULER</Button>
      <Button onClick={handleSave}>SAUVEGARDER</Button>
      </DialogActions>
    </Dialog>
  );
}
