import React from "react";
import LineChart from "./Charts/LineChart";
import BarChart from "./Charts/BarsChart";
import PieChart from "./Charts/PieChart";
import "./ChartSection.css";

const ChartSection = () => {

  return (
    <div className="chart-container">
      <div className="one-third-container">
        <LineChart
        />
      </div>
      <div className="one-third-container">
        <BarChart
        />
      </div>
      <div className="one-third-container">
        <PieChart
        />
      </div>
    </div>
  );
};

export default ChartSection;
