import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

export default function DetailsDialog({ open, onClose, details }) {
  if (!details) return null; // Retour anticipé si les détails ne sont pas disponibles

  // Convertir le booléen en une chaîne lisible par un humain
  const clickedBefore10Seconds = details.clicked_before_10_seconds ? 'Vrai' : 'Faux';

  // Accéder en toute sécurité aux propriétés du client en utilisant l'opérateur de chaînage optionnel
  const clientName = details.id_client?.name || 'N/A';
  const clientEmail = details.id_client?.email || 'N/A';
  const clientPhone = details.id_client?.phoneNumber || 'N/A';
  const clientAddress = details.id_client?.address || 'N/A';
  const clientIpAddress = details.id_client?.ip_address || 'N/A';
  const clientIdTranscript = details.id_client?.id_transcript || 'N/A';

  return (
    <Dialog onClose={() => onClose()} open={open} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: '#05203c',
          color: 'white',
          p: 2
        }}
      >
        Rapport détaillé d'appel et vue d'ensemble de l'interaction
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => onClose()}
          aria-label="fermer"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: '#f5f5f5',
          p: 3,
          borderRadius: '8px',
          mt: 2 // Ajout de marge en haut pour espacer le titre et le contenu
        }}
      >
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Catégorie :</strong> {details.category}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>ID de produit :</strong> {details.product_id}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>A quitté l'appel avant 10 secondes :</strong> {clickedBefore10Seconds}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>ID :</strong> {details._id}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>ID de la transcription :</strong> {clientIdTranscript}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Date et heure :</strong> {new Date(details.datetime).toLocaleString()}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Nom du client :</strong> {clientName}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Email du client :</strong> {clientEmail}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Numéro de téléphone :</strong> {clientPhone}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Adresse de livraison du client :</strong> {clientAddress}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Adresse IP du client:Jeton :</strong> {clientIpAddress}
        </Typography>

      </DialogContent>
    </Dialog>
  );
}
