import React, { useState } from 'react';
import axios from 'axios';  // Import axios
import './SignIn.css';

const SignIn = () => {
  const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //call api to het the tocken 
      const response = await axios.post(`${baseUrl}/api/admin/login`, {
        username: email, // Assuming the backend uses 'username'
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = response.data;

      if (response.status === 200) {
        // Save token to localStorage
        localStorage.setItem('token', data.token);
        // Redirect to dashboard
        window.location.href = '/dashboard';
  
      } else {
        // Handle non-200 responses
        setError(data.message || 'Login failed');
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Display specific error message for 400 Bad Request
        setError(err.response.data.message || 'Invalid username or password');
      } else {
        console.error('Error:', err);
        // Set generic error message for other errors
        setError(err.response.data.message  );
      }
    }
  };

  return (
    <div className="signin-container">
      <form className="signin-form" onSubmit={handleSubmit}>
      <h2>CONNEXION</h2>

<p>Bienvenue sur le tableau de bord Analytics.  </p>
        <div className="input-container">
          <input
            type="text"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <i className="username-icon"></i>
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <i className="password-icon"></i>
        </div>
        <div className="options">
          <label>
          <input type="checkbox" /> Se souvenir de moi
                    </label>
          <a href="/forgot-password" className="forgot-password">Mot de passe oublié ?
</a>
        </div>
        <button type="submit">connexion</button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default SignIn;
