import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AlignItemsList from "../AlignItemsList";
import "./ActionsComponent.css";

const ActionsComponent = ({ questionsContent, handleViewSatisfiedQuestions, handleViewUnsatisfiedQuestions }) => {
  const [title, setTitle] = useState("");

  const handleSatisfiedClick = () => {
    handleViewSatisfiedQuestions();
    setTitle("Liste des réponses satisfaites pour chaque transcription");
  };

  const handleUnsatisfiedClick = () => {
    handleViewUnsatisfiedQuestions();
    setTitle("Liste des réponses insatisfaites pour chaque transcription");
  };

  return (
    <div className="actions">
      <div className="button-container">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSatisfiedClick}
          className="action-button"
        >
    <span>Voir les réponses satisfaites</span>
    </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUnsatisfiedClick}
          className="action-button"
        >
    <span>Voir les réponses insatisfaites</span>
    </Button>
      </div>

      {title && (
        <Card className="questions-title-card">
          <CardContent>
            <Typography variant="h6" component="h3">
              {title}
            </Typography>
          </CardContent>
        </Card>
      )}

      {questionsContent.length > 0 && (
        <div className="questions-content">
          <AlignItemsList items={questionsContent} />
        </div>
      )}
    </div>
  );
};

export default ActionsComponent;
