import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Header from "./Header/Header";
import ChartSection from "./ChartSection/ChartSection";
import FiltersComponent from "./FiltersComponent/FiltersComponent";
import StatisticsComponent from "./StatisticsComponent/StatisticsComponent";
import ActionsComponent from "./ActionsComponent/ActionsComponent";
import "./Dashboard.css";

const Dashboard = () => {
  const [uniqueClientsCount, setUniqueClientsCount] = useState(0);
  const [questionsSatisfied, setQuestionsSatisfied] = useState(null);
  const [questionsUnsatisfied, setQuestionsUnsatisfied] = useState(null);
  const [bounceRate, setBounceRate] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [satisfiedQuestionsContent, setSatisfiedQuestionsContent] = useState([]);
  const [unsatisfiedQuestionsContent, setUnsatisfiedQuestionsContent] = useState([]);
  const [questionsContent, setQuestionsContent] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMethod, setSearchMethod] = useState("");

  const [totalClientCount,setTotalClientCount]= useState(0);
  const [TotalClientCountLeftTheCall,setTotalClientCountLeftTheCall]= useState(0);

  const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL;

  const fetchData = useCallback(async () => {
    try {
      let responses;
  
      // Define common parameters
      const commonParams = searchTerm ? { product_id: searchTerm } : { startDate, endDate };
  
      // Check if searchTerm or date is empty
      if (!searchTerm && (!startDate || !endDate)) {
        // Fetch all data if no search term and no date range
        responses = await Promise.all([
          axios.get(`${baseUrl}/api/unique-client`),
          axios.get(`${baseUrl}/api/satisfied-question`),
          axios.get(`${baseUrl}/api/unsatisfied-question`),
          axios.get(`${baseUrl}/api/client-count`),
          axios.get(`${baseUrl}/api/total-clients-clicked-before-10-seconds`)

        ]);
      } else if (searchMethod === 'byproductid' ) {
        responses = await Promise.all([
          axios.post(`${baseUrl}/api/unique-client-byproduct`, commonParams),
          axios.post(`${baseUrl}/api/satisfied-question/filter/byproductid`, commonParams),
          axios.post(`${baseUrl}/api/unsatisfied-question/filter/byproductid`, commonParams),
          axios.post(`${baseUrl}/api/client-count-byproduct`, commonParams),
          axios.post(`${baseUrl}/api/total-clients-clicked-before-10-seconds-byproduct`, commonParams)
        ]);
      } else if (searchMethod === 'byperiod') {
        responses = await Promise.all([
          axios.post(`${baseUrl}/api/unique-client-byperiod`, commonParams),
          axios.post(`${baseUrl}/api/satisfied-question/filter/byperiod`, commonParams),
          axios.post(`${baseUrl}/api/unsatisfied-question/filter/byperiod`, commonParams),
          axios.post(`${baseUrl}/api/client-count-byperiod`, commonParams),
          axios.post(`${baseUrl}/api/total-clients-clicked-before-10-seconds-byperiod`, commonParams)
        ]);
      } else if (searchMethod === 'byproduct-period') {
        responses = await Promise.all([
          axios.post(`${baseUrl}/api/unique-client-byproduct-period`, { ...commonParams, startDate, endDate }),
          axios.post(`${baseUrl}/api/satisfied-question/filter/byproduct-period`, { ...commonParams, startDate, endDate }),
          axios.post(`${baseUrl}/api/unsatisfied-question/filter/byproduct-period`, { ...commonParams, startDate, endDate }),
          axios.post(`${baseUrl}/api/client-count-byproduct-period`, { ...commonParams, startDate, endDate }),
          axios.post(`${baseUrl}/api/total-clients-clicked-before-10-seconds-byperiod-product`, { ...commonParams, startDate, endDate })
        ]);
      }
      
      if (responses && responses.length === 5) {

      console.log('Responses:', responses);

      // Process responses
      const [uniqueClientsResponse, satisfiedQuestionsResponse, unsatisfiedQuestionsResponse, totalClientrecievedResponse, clickedBefore10SecoundsResponse] = responses;

      if (uniqueClientsResponse) {
        setUniqueClientsCount(uniqueClientsResponse.data);
      }
  
      if (satisfiedQuestionsResponse?.data) {
        setSatisfiedQuestionsContent(satisfiedQuestionsResponse.data);
        setQuestionsSatisfied(countContentArrays(satisfiedQuestionsResponse.data));
      }
  
      if (unsatisfiedQuestionsResponse?.data) {
        setUnsatisfiedQuestionsContent(unsatisfiedQuestionsResponse.data);
        setQuestionsUnsatisfied(countContentArrays(unsatisfiedQuestionsResponse.data));
      }
  
      if (clickedBefore10SecoundsResponse.data || totalClientrecievedResponse.data) {
        const clickedCount = parseFloat(clickedBefore10SecoundsResponse.data)  ;
        const totalCount = parseFloat(totalClientrecievedResponse.data) ;
        setTotalClientCount(totalClientrecievedResponse.data)
        setTotalClientCountLeftTheCall(clickedBefore10SecoundsResponse.data)
        if (totalCount > 0) {


          setBounceRate((clickedCount / totalCount) * 100);
        } else {
          setBounceRate(0);
        }
      } else {
        setBounceRate(0); // Either data is missing, default to 0
      }
    }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }, [baseUrl, searchTerm, searchMethod, startDate, endDate]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewSatisfiedQuestions = () => {
    setQuestionsContent(satisfiedQuestionsContent);
  };

  const handleViewUnsatisfiedQuestions = () => {
    setQuestionsContent(unsatisfiedQuestionsContent);
  };

  const handleSearch = () => {
    console.log("search method called", searchTerm);
    setQuestionsContent([]);
    if (searchTerm && startDate && endDate && new Date(startDate) < new Date(endDate)) {
      setSearchMethod('byproduct-period');
    } else if (startDate && endDate && new Date(startDate) < new Date(endDate)) {
      setSearchMethod('byperiod');
    } else if (searchTerm) {
      setSearchMethod('byproductid');
    } else {
      setStartDate("");
      setEndDate("");
      console.log("Search term is empty or invalid date range. No search will be performed.");
    }
  };

  function countContentArrays(data) {
    return data.reduce((total, item) => {
      if (Array.isArray(item.content)) {
        return total + item.content.length;
      }
      return total;
    }, 0);
  }

  

  return (
    <div className="dashboard">
      <Header />
      <main>
        <h1>Welcome to your dashboard</h1>
        {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
        <ChartSection />
        <FiltersComponent
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          setSearchTerm={setSearchTerm}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSearch={handleSearch}
        />
        <StatisticsComponent
          uniqueClientsCount={uniqueClientsCount}
          totalClientCountLeftTheCall={TotalClientCountLeftTheCall}
          totalClientCount={totalClientCount}

          questionsSatisfied={questionsSatisfied}
          questionsUnsatisfied={questionsUnsatisfied}
          bounceRate={bounceRate.toFixed(2)}
        />
        <ActionsComponent
          questionsContent={questionsContent}
          handleViewSatisfiedQuestions={handleViewSatisfiedQuestions}
          handleViewUnsatisfiedQuestions={handleViewUnsatisfiedQuestions}
          satisfiedQuestionsContent={satisfiedQuestionsContent}
        />
      </main>
    </div>
  );
};

export default Dashboard;
 