import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import axios from 'axios';

export default function ChartsOverview() {
  const [chartData, setChartData] = React.useState({ dates: [], before10Seconds: [], after10Seconds: [] });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL

        // Fetch transcript data
        const transcriptResponse = await axios.get(`${baseUrl}/api/transcript`);
        const transcriptData = transcriptResponse.data;

        const now = new Date();
        const pastWeek = new Date(now);
        pastWeek.setDate(now.getDate() - 6); // Start 7 days ago, including today

        // Generate array of dates for the past 7 days including today
        const datesArray = [];
        for (let i = 0; i < 7; i++) {
          const date = new Date(pastWeek);
          date.setDate(date.getDate() + i);
          datesArray.push(date.toDateString());
        }

        // Initialize counts for each date
        const counts = {};
        datesArray.forEach(date => {
          counts[date] = { before10Seconds: 0, after10Seconds: 0 };
        });

        // Populate counts from transcript data
        transcriptData.forEach(item => {
          const date = new Date(item.datetime).toDateString();
          if (counts[date]) {
            if (item.clicked_before_10_seconds) {
              counts[date].before10Seconds += 1;
            } else {
              counts[date].after10Seconds += 1;
            }
          }
        });

        // Prepare data for the chart
        const before10Seconds = datesArray.map(date => counts[date].before10Seconds);
        const after10Seconds = datesArray.map(date => counts[date].after10Seconds);

        setChartData({ dates: datesArray, before10Seconds, after10Seconds });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: '100%', height: 350 }}>
<h4 style={{ textAlign: 'center' }}>Les clients ont quitté l'appel avant et après 10 secondes au cours des 7 derniers jours</h4>
<BarChart
      
        series={[
          { data: chartData.before10Seconds, label: 'avant 10 S', color: 'red' }, // Customize colors as needed
          { data: chartData.after10Seconds, label: 'après 10 s', color: 'blue' }   // Customize colors as needed
        ]}
        height={290}
        xAxis={[{ data: chartData.dates, scaleType: 'band' }]}
        yAxis={[{
          tickFormatter: (value) => Math.round(value), // Ensure y-axis displays whole numbers
          min: 0, // Ensure y-axis starts from 0
          max: Math.max(...chartData.before10Seconds, ...chartData.after10Seconds) + 1 // Dynamically set max value
        }]}
        margin={{ top: 30, bottom: 30, left: 40, right: 10 }}
      />
    </div>
  );
}
