import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';  // Import Navigate for redirection
import SignIn from './components/SignIn';  // Ensure the path is correct
import Dashboard from './components/Dashboard';  // Ensure the path is correct

const App = () => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check if user is authenticated based on token

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={isAuthenticated ? <Navigate to="/dashboard" /> : <SignIn />} />
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/signin" />} />
        <Route path="/" element={<Navigate to="/signin" />} /> {/* Redirect to /signin by default */}
      </Routes>
    </Router>
  );
}

export default App;
