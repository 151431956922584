import * as React from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import Tooltip from "@mui/material/Tooltip";
import DetailsDialog from "./DetailsDialog";
import FAQUpdateDialog from "./FAQUpdateDialog";
import MessageDialog from "./MessageDialog";
import iaImage from "./assets/ia.png";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import axios from "axios";

const baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL;

const buttonStyle = {
  position: "relative",
  overflow: "hidden",
  padding: "8px 16px",
  borderRadius: "4px",
  border: "1px solid #3f51b5",
  color: "#3f51b5",
  backgroundColor: "transparent",
  transition: "color 0.3s, border-color 0.3s",
  animation: "greenPulse 2s infinite",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "300%",
    height: "300%",
    borderRadius: "50%",
    backgroundColor: "rgba(63, 81, 181, 0.2)",
    transform: "translate(-50%, -50%) scale(0)",
    animation: "pulse 1s infinite",
    zIndex: 0,
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#3f51b5",
    borderColor: "#3f51b5",
  },
  "& .MuiButton-label": {
    position: "relative",
    zIndex: 1,
  },
  // Responsive styles
  "@media (max-width: 600px)": {
    padding: "4px 8px",
    width:"80px",
    fontSize: "12px",
  },
};
const buttonStyleFaq = {
  
  "&:hover": {
    color: "#fff",
    backgroundColor: "#f50057", // Secondary color
    borderColor: "#f50057", // Secondary color
  },
  "& .MuiButton-label": {
    position: "relative",
    zIndex: 1,
  },
  // Responsive styles
  "@media (max-width: 600px)": {
    padding: "4px 8px",
    fontSize: "12px",
    width: "80px", // Adjust width as needed
  },
};

const keyframes = `
@-webkit-keyframes greenPulse {
  from { background-color: #749a02; -webkit-box-shadow: 0 0 9px #333; }
  50% { background-color: #91bd09; -webkit-box-shadow: 0 0 18px #91bd09; }
  to { background-color: #749a02; -webkit-box-shadow: 0 0 9px #333; }
}

@keyframes greenPulse {
  from { background-color: #749a02; box-shadow: 0 0 9px #333; }
  50% { background-color: #91bd09; box-shadow: 0 0 18px #91bd09; }
  to { background-color: #749a02; box-shadow: 0 0 9px #333; }
}

@-webkit-keyframes pulse {
  from { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  50% { transform: translate(-50%, -50%) scale(1); opacity: 0; }
  to { transform: translate(-50%, -50%) scale(0); opacity: 1; }
}

@keyframes pulse {
  from { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  50% { transform: translate(-50%, -50%) scale(1); opacity: 0; }
  to { transform: translate(-50%, -50%) scale(0); opacity: 1; }
}
`;

export default function AlignItemsList({ items }) {
  const [open, setOpen] = React.useState(false);
  const [selectedDetails, setSelectedDetails] = React.useState(null);
  const [expandedItem, setExpandedItem] = React.useState(null);
  const [openUpdateFAQDialog, setOpenUpdateFAQDialog] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState(false);

  const [faqIA, setFaqIA] = React.useState("");
  const [FaqClient, setFaqClient] = React.useState("");

  const handleClickOpen = (item) => {
    setSelectedDetails(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDetails(null);
  };

  const handleToggleClientMessages = (itemId) => {
    setExpandedItem((prev) => (prev === itemId ? null : itemId));
  };

  const handleUpdateFAQ = (e, clientMessage, iaMessage) => {
    e.stopPropagation();
    setFaqClient(clientMessage);

    setFaqIA(iaMessage);
    setOpenUpdateFAQDialog(true);
  };

  const handleCloseUpdateFAQDialog = () => {
    setOpenUpdateFAQDialog(false);
  };

  const handleSaveFAQ = (updatedContent) => {
    console.log("Updated FAQ Content:", updatedContent);
    setOpenUpdateFAQDialog(false);
  };

  const handleFetchPopupData = async (itemId) => {
    console.log("Fetching" + itemId);
    try {
      const response = await axios.post(`${baseUrl}/api/transcript/get-by-id`, {
        id: itemId,
      });
      setPopupContent(response.data.content);
      //setOpenPopup(false);
      setOpenPopup(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setPopupContent([]);
  };

  return (
    <>
      <style>{keyframes}</style>

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
  {items.map((item) => (
    <Tooltip
      key={item._id}
      title="Click inside the card to view details"
      placement="top"
      arrow
    >
      <Card
        variant="outlined"
        sx={{
          mb: 2,
          borderRadius: 2,
          border: "1px solid #e0e0e0",
          cursor: "pointer",
          boxShadow: 3,
        }}
        onClick={() => handleClickOpen(item)}
      >
        <CardHeader
          avatar={<Avatar alt="AI" src={iaImage} />}
          title={`Assistant en Intelligence Commerciale `}
          titleTypographyProps={{ variant: "body2", fontWeight: "bold" }}
          sx={{ bgcolor: "#f5f5f5" }}
          action={
            <Button
              variant="outlined"
              color="primary"
              sx={buttonStyle}
              onClick={(e) => {
                e.stopPropagation();
                handleFetchPopupData(item.id_client.id_transcript);
              }}
            >
              Ouvrir le dialogue {/* Changed title */}
            </Button>
          }
        />
        <CardContent>
          {item.content.map((messagePair, index) => {
            const [clientMessage, iaMessage] = messagePair;

            return (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}
              >
                {expandedItem === item._id && (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      {clientMessage}
                    </Typography>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.primary"
                    style={{ flex: 1 }}
                  >
                    {iaMessage}
                  </Typography>

                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={buttonStyleFaq}
                    onClick={(e) =>
                      handleUpdateFAQ(e, clientMessage, iaMessage)
                    }
                  >
                    Mettre à jour FAQ
                  </Button>
                </div>
                <Divider sx={{ mb: 2 }} />
              </div>
            );
          })}
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleToggleClientMessages(item._id);
            }}
          >
            {expandedItem === item._id ? (
              <>
                Masquer le message du client <VscEyeClosed />
              </>
            ) : (
              <>
                Afficher le message du client <VscEye />
              </>
            )}
          </Button>
        </CardContent>
      </Card>
    </Tooltip>
  ))}
</List>


      <DetailsDialog
        open={open}
        onClose={handleClose}
        details={selectedDetails}
      />
      {openUpdateFAQDialog && (
        <FAQUpdateDialog
          open={openUpdateFAQDialog}
          onClose={handleCloseUpdateFAQDialog}
          initialQuestion={FaqClient}
          initialAnswer={faqIA}
          onSave={handleSaveFAQ}
        />
      )}
      {openPopup && (
        <MessageDialog
          open={openPopup}
          onClose={handleClosePopup}
          content={popupContent}
        />
      )}
    </>
  );
}
